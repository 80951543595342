#footer{
  background-color: lighten($dark, 20);
  color: white;
  position: relative;
  @include media-breakpoint-down(sm) {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 20px;
    svg{
      display: none;
    }
  }
  .footer__site{
    padding-top: 8rem;
    padding-bottom: 8rem;
    @include media-breakpoint-down(sm) {
      padding-top: 2rem;
    }
    h2{
      color: $primary;
      font-size: 18px;
    }
    a:not(.btn){
      color: $secondary;
      font-family: $montserrat_bold;
    }
  }
  .bottom{
    background-color: white;
    @include media-breakpoint-down(sm) {
      margin-left: -30px;
      margin-right: -30px;
      padding: 1rem;
    }
    &, .nav-link{
      color: $dark;
      font-size: 14px;
    }
    a{
      &:hover{
        color: $primary;
      }
    }
  }
  .social_link{
    width: 40px;
    height: 40px;
    display: flex;
    color: white;
    border: 2px solid $white;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    transition: all .3s ease;
    background-color: transparent;
    &:hover{
      background-color: $secondary;
    }
  }
  .social__links{
    gap: 2rem;
    @include media-breakpoint-down(sm) {
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}

.logos__partners{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #333;
  padding-top: 2rem;
  gap: 2rem;
  margin-top: 30px;
  img{
    max-width: 90px;
    height: auto;
  }
}

.footer__tabs{
  @include media-breakpoint-up(lg) {
    display: grid;
    min-height: 220px;
    grid-template-columns:40% 60%;

  }
  @include media-breakpoint-down(sm) {
    .nav-pills{
      flex-direction: row  !important;
      justify-content: center;
      position: relative;
      z-index: 1;
      .nav-link:not(.active){

        background-color: lighten($dark, 20);
      }
    }
  }
  .tab-content{
    @include media-breakpoint-up(lg) {

      border-left: 1px solid lighten($dark, 30);

    }

    @include media-breakpoint-down(lg) {
      border-top: 1px solid lighten($dark, 30);
      margin-bottom: 1rem;
      margin-top: -10px;
    }
    padding-left: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
