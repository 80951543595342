.search__wrapper{
  display: grid;
  gap: 1rem;
  overflow: hidden;
  position: relative;
  @include media-breakpoint-up(lg) {
    grid-template-columns: 2fr 1fr;
    height: calc(100vh - 61px);

  }
}
.search-results__list{
  position: relative;
}
.search-panel__list{
  padding-left: 1rem;
  padding-right: 2rem;
  &.map-is-on{
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
.search-panel__list, .criteria__form{
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 100px;
}
.criteria__form{
  overflow-x: hidden;
  background-color: $primary;
  position: absolute;
  left: -100%;
  top: -1px;
  z-index: 4;
  color: darken($primary, 40);
  transition: all .4s ease;
  border: 1px solid rgba(114, 199, 210, 0.28);
  border-left: 0;
  @include media-breakpoint-down(sm) {

    left: -110%;

  }
  // color: white;
  &--title{
    display: flex;
    padding: 10px;
    position: sticky;
    top: 0;
    align-items: center;
    justify-content: space-between;
    background-color:darken($primary, 20);
    z-index: 1;
    border-bottom: 1px solid rgba(114, 199, 210, 0.28);
    // box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    h2{
      font-size: 20px;
      color: $white;
      line-height: 1;
      margin: 0;
    }
    button{
      appearance: none;
      border: 0;
      background-color: $secondary;
      color: white;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
  }
  .criteria__list>div{
    padding: 20px;
    border-top: 1px solid lighten($primary, 10);
    border-bottom: 1px solid darken($primary, 10);
  }
  input[type="text"], select{
    border: 0;
    padding: 10px;
    border-radius: 2px;
    background-color: rgba(white, .5);
  }
  select{
    min-width: 100%;
    border: 1px solid rgba(114, 199, 210, 0.28);
  }

  .select2{
    min-width: 100%;
    border: 0;
    margin-top: 5px;
    .select2-selection--single{
      border: 1px solid rgba(114, 199, 210, 0.28);
      background-color: rgba(white, .5);
    }
  }
  .select2-container--default .select2-selection--single{
    border-radius: 0;
    height: 38px;
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height: 38px;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 36px;
    right: 12px;
    top: -3px;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow b{
    height: 12px;
    width: 12px;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  }

  .select2-container--default .select2-selection--single .select2-selection__clear {
    margin-right: 15px;
  }

  .dates{
    display: flex;
    gap: 10px;
    flex-direction: column;
    input{
      width: 100%;
    }
  }
  div.label{
    padding: 0.5rem;
    text-transform: uppercase;
    font-family: $montserrat_bold;
    color: $dark;
    margin-bottom: 10px;
  }
  ul{
    list-style-type: none;
    padding: 0;
    li{
      margin-bottom: 10px;
    }
  }
}
.is-opened{
  .criteria__form{
    left: 0;
  }

  #id_ajax_loader{
    // display: block !important;
    // z-index: 1;
  }
}
.results-wrapper{
  display: grid;
  margin-bottom: 20px;
  // grid-template-columns:1fr 1fr 1fr;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat( auto-fill, minmax(280px, 1fr) );
    gap: 2rem;

  }
  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
#id_map {
  height: 100vh;
  z-index: 0;
}
.action__btn{
  border-radius: 4px !important;
}
.express_search_form{
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 3;
  display: flex;
  gap: 1rem;
  @include media-breakpoint-down(lg) {
    position: static;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }
  @include media-breakpoint-down(sm) {
    justify-content: space-between;
    .action__btn{
      padding: 0.4rem 1rem;
      font-size: 14px;
    }
  }

}
.result__count{
  position: sticky;
  text-align: right;
  top: 0px;
  background-color: white;
  z-index: 1;
  padding: 2rem;
  padding-right: 0;
  padding-left: 130px;
  font-weight: bold;
}
.icn__location{
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url(../img/icons/location_icn.svg);
  background-size: cover;
}
.result{
  figure{
    height: 100%;
    position: relative;
    display: grid;
    padding: 10px;
    // grid-template-columns: 1fr 1fr;
    border-radius: 4px;
    border: 1px solid rgba(114, 199, 210, 0.28);

    box-shadow: 0px 13px 33px 0px rgba(0, 0, 0, 0.05);
    .rating{
      color: $yellow;
      font-size: 10px;
      margin-bottom: 10px;
      img{
        display: inline-block;
        margin-left: 0.1rem;
      }

      img[title="keys"]{
        width: 12px;
        height: auto;
      }
    }
  }
  .is_partner{
    position: absolute;
    top: -2px;
    left: 20px;
    font-size: 25px;
    color: gold;
    text-shadow: 2px 2px 4px rgba($dark, .6);
  }
  .item-locality{
    font-size: 13px;
  }
  .img-wrapper{
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    img{
      border-radius: 4px;
      transition: all .3s ease;
    }
    a{
      display: block;
    }
    .item-online-booking-badge{
      position: absolute;
      background-color: rgba($dark, .6);
      font-size: 80%;
      top: 30px;
      left: 10px;
      padding: .2rem 0.5rem;
      font-weight: bold;
      color: goldenrod;
    }
  }
  figcaption{
    padding: 1rem;
    padding-top: 2rem;
    span{
      display: block;
    }
    .item-name{
      text-decoration: none;
      text-transform: uppercase;
      font-family: $montserrat_bold;
      line-height: 1.3;
      display: block;
      margin-bottom: 10px;
      color: inherit;
      transition: all .3s ease;
    }
  }
  &:hover{
    figure{
      .img-wrapper{
        img{
          transform: scale(1.3);
        }
      }
      .item-name{
        color: $primary;
      }
    }
  }

}
.wishlist-controls-wrapper{
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: white;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  a, button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: $primary;
    i{
      font-size: 24px;
    }
    &::after{
      content: "+";
      font-size:14px;
      font-weight: bold;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: $primary;
      color: white;
      border:2px solid $white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      top: -5px;
      right: -6px;
    }
    &.remove__from{
      &::after{
        background-color: darken($primary, 20);
        content: "-";
      }
    }
  }
  button{
    background-color: white;
    border: 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    &::after{
      right: -2px;
    }
  }
}

#id_ajax_loader{
  background-color: rgba($dark, .9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  img{
    width: 300px;
    height: auto;
  }
}
.leaflet-container {
  font-family: 'Work Sans', sans-serif;
  .leaflet-popup-content-wrapper{
    background-color: white !important;

  }
  figure{
    margin-bottom: 0;
    border: 0;
    grid-template-columns: 1fr;
  }

  a.leaflet-popup-close-button{
    top: 10px;
    right: 10px;
    background-color: $dark;
    color: white;
    font-family: 'Work Sans', sans-serif;
  }
  .leaflet-popup-content-wrapper{
    border-radius: 0;
    border: 0;
    .leaflet-popup-content{
      margin: 0;
      font-size: 16px;
    }
  }
  .map__widget{
    color: $dark;
    background-color: white;
    text-decoration: none;
    display: block;
    .widget__content{
      padding: 1rem;
    }
    .item-name{
      font-size: 16px;
      margin-bottom: 10px;
      span{
        text-transform: uppercase;
        font-weight: 700;
        display: block;
        margin-bottom: 10px;
      }
    }
  }

}

.search-results__list div[data-is-in-wishlist="true"]{
  filter: grayscale(1);
  opacity: .3;
}


.pagination{
  gap: 1rem;
  justify-content: flex-end;
  a, li:not(.page-counter) span{
    border-radius: 50% !important;
    font-weight: bold;
    min-width: 40px;
    min-height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: #ffff;
    box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.15);
    &:hover{
      background-color: $primary;
      color: white;
    }

  }

}


#id_activity_results_list{
  .footer{
    svg{
      display: none
    }
  }
}




@supports (-webkit-appearance: none) or (-moz-appearance: none) {

  .criteria__list input[type=radio]{
    width: 21px;
    border-radius: 12px;
  }
  .criteria__list input[type=checkbox],
  .criteria__list input[type=radio]
  {
    --active: #4d8b94;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #4d8b94;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  .criteria__list input[type=checkbox]:after,
  .criteria__list input[type=radio]:after
  {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }
  .criteria__list input[type=checkbox]:checked,
  .criteria__list input[type=radio]:checked
  {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  .criteria__list input[type=checkbox]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  .criteria__list input[type=checkbox]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  .criteria__list input[type=checkbox]:disabled + label {
    cursor: not-allowed;
  }
  .criteria__list input[type=checkbox]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  .criteria__list input[type=checkbox]:focus,
  .criteria__list input[type=radio]:focus
  {
    box-shadow: 0 0 0 var(--focus);
  }
  .criteria__list input[type=checkbox]:not(.switch) {
    width: 21px;
  }
  .criteria__list input[type=checkbox]:not(.switch):after,
  .criteria__list input[type=radio]:not(.switch):after
  {
    opacity: var(--o, 0);
  }
  .criteria__list input[type=checkbox]:not(.switch):checked,
  .criteria__list input[type=radio]:not(.switch):checked
  {
    --o: 1;
  }
  .criteria__list input[type=checkbox] + label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 4px;
  }

  .criteria__list input[type=checkbox]:not(.switch) {
    border-radius: 7px;
  }
  .criteria__list input[type=checkbox]:not(.switch):after,
  .criteria__list input[type=radio]:not(.switch):after
  {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }
  .criteria__list input[type=checkbox]:not(.switch):checked ,
  .criteria__list input[type=radio]:not(.switch):checked
  {
    --r: 43deg;
  }
}

.criteria__list * {
  box-sizing: inherit;
}
.criteria__list *:before,
.criteria__list *:after {
  box-sizing: inherit;
}





#id_wishlist_map{
  height: 400px;
}



.wishlist__list{
  margin-top: 3rem;
  display: grid;
  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  gap: 1rem;
  .alert{
    grid-column: 1/-1;
    font-weight: bold;
  }
  @include media-breakpoint-down(lg) {

  }
}


.award{
  font-family: $montserrat_bold;
  font-size: 20px;
}




.events_cms{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }
}
