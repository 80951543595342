
.header__site{
  position: relative;
  z-index: 999;
  border-bottom: 1px solid #eee;
  background-color: white;
  transition: background-color 0.3s ease;
  @include media-breakpoint-down(sm) {
    position: sticky;
    top: 0;
    background-color: white;
  }
  .top__bar{
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .6rem 1rem;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    nav{
      align-items: center;
      gap: 1rem;
      @include media-breakpoint-down(sm) {
        min-width: 100%;
        justify-content: space-between;
      }
      &:first-child{
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      > a, button{
        font-size: 90%;
        color: white;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        gap: .5rem;
        position: relative;
        text-decoration: none;
        transition: all .3s ease;
        font-family: $montserrat_bold;
        &:hover{
          color: white;
        }
      }
      &:first-child{
        a{
          &:hover{
            color: lighten($primary, 30);
            span{
              background-color: darken($primary, 30);
            }
          }
        }
      }
      a >span.icon{
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        border-radius: 20px;
        transition: all .3s ease;
        i{
          font-size: 12px;
        }
      }
      .phone{
        font-family: $montserrat_bold;
        font-size: 14px;
        border: 1px solid white;
        padding-right: 15px;
        border-radius: 20px;
        transition: all .3s ease;
        span{
          border: 0 !important;
          margin-right: -10px;
        }
        &:hover{
          background-color: darken($primary, 30);
        }
      }
    }
    i{
      font-size: 18px;
    }
    ul{
      margin-bottom: 0;
    }

    .wishlist{
      .icon{
        margin-right: 10px;
      }
      &-count{
        display: flex;
        min-height: 20px;
        min-width: 20px;
        line-height: 1;
        align-items: center;
        justify-content: center;
        background-color: darken($primary, 20);
        font-size: 10px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 25px;
        border: 1px solid $white;

      }
    }
    .btn.btn-primary{
      box-shadow: none;
    }

  }
  .navbar-brand{
    padding: 10px 0;
    div{
      position: relative;
      height: 85px;
      width: 120px;
      padding: 10px;
      display: grid;
      img{
        grid-area: 1 / 1 / 2 / 2;
        // transition: all .3s ease;
        // transform-origin: center;
        max-width: 100%;
        height: auto;
        &:first-child{
          // opacity: 0;
          // visibility: hidden;
          // transform: scale(.3);
          // ! TODO: Create a single logo including everything
          transform: scale(.8);
          position: absolute;
          left: 20px;
          top: 12px;
        }
        // &:last-child{
        //   transition: transform .3s ease, opacity .6s ease;
        //   // width: 80px;
        // }
      }
    }
    .brand__text{
      // opacity: 0;
      // visibility: hidden;
      // transform: translateX(-100%);
      // transition: all .3s cubic-bezier(.87,1.24,.83,.67);
      // position: absolute;
    }
    &:hover{
      .brand__text{
        opacity: 1;
        visibility: visible;
        // transform: translateX(115px);
      }
      div{
        img{
          &:first-child{
            opacity: 1;
            visibility: visible;
            // transform: scale(.8);

          }
          &:last-child{
            // transform: scale(.35);
          }
        }
      }
    }
  }
  .navbar{
    padding: 0;
  }
  .nav-link{
    text-transform: uppercase;
    letter-spacing: 1px;
    color: lighten($dark, 10);
    font-family: $montserrat_bold;
    &:hover{
      color: $primary;
    }
    &::after{
      display: none;
    }
  }
  .navbar-collapse{
    align-self: stretch;
  }
  .navbar{
    .first__nav{
      align-self: stretch;
      .nav-item{
        position: unset;
        display: flex;
        height: 100%;
        @include media-breakpoint-down(sm) {

          width: 100%;
        }
        >a{
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: column;
          height: 100%;
          gap: 1rem;
          @include media-breakpoint-up(sm) {
            justify-content: center;

          }
          @include media-breakpoint-down(sm) {
            border-bottom: 1px solid darken($spa,10);
            border-top: 1px solid lighten($spa,20);
            flex-direction: row;
            padding: 15px 10px;
            width: 100%;
          }
        }
        &.ancestor,
        &.active,
        &:hover{
          >a{
            color: $primary;
          }
        }
        .icon{
          display: block;
          width: 20px;
          height: 20px;
          background-size: cover;
          &.icon-discover{
            background-image: url(../img/discover.svg);
          }

          &.icon-tostay{
            background-image: url(../img/sejourner.svg);
          }
          &.icon-agenda_i{
            background-image: url(../img/agenda.svg);
          }
          &.icon-pratic{
            background-image: url(../img/pratique.svg);
          }
          &.icon-well-being{
            background-image: url(../img/spa.svg);
          }
          &.icon-nature{
            background-image: url(../img/nature.svg);
          }
        }
        .submenu__nav {

          padding: 4rem;
          position: absolute;
          left: 0;
          opacity: 0;
          visibility: hidden;
          width: 100%;
          background-color: darken($white,3);
          color: #000;
          overflow-x: hidden;
          overflow-y: auto;
          transition: all 0.5s cubic-bezier(0.81, 0.61, 0.25, 1), opacity 0.8s ease;
          pointer-events: none;
          top: 100%;
          height: auto;
          max-height: calc(100vh - 80px);
          transform: translateY(-140%);
          z-index: -1;
          @include media-breakpoint-up(lg) {

          }
          a {

            transition: transform 0.3s, opacity 0.3s;
            text-transform: uppercase;
            font-size: 90%;
            letter-spacing: 1px;
            display: flex;
            font-weight: 600;
            line-height: 1.2;
            border-radius: 20px;
            overflow: hidden;
            margin: 0;
            padding: 0;
            &:hover{
              color: $danger;
            }
          }
        }
        &:hover{
          >.submenu__nav{
            @include media-breakpoint-up(lg) {
              transform: translateY(0);
              pointer-events: auto;
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

    }
  }
  .btn-spa{
    border-color: $spa2;
    border-width: 1px;
    display: inline-flex;
    align-items: center;
    gap: .5rem;
    color: $spa2;
    i{
      display: inline-block;
      background-image: url(../img/thermal.svg);
      width: 24px;
      height: 24px;
      background-size: cover;
    }
    &::after{
      display: none;
    }
  }
  &.sticky {
    @include media-breakpoint-up(lg) {
      position: fixed;
      top: 0;
      width: 100%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      animation: fade_move_down_2 2s cubic-bezier(0.49, 0.13, 0.75, 0.96) ;

    }
  }
}
.submenu_wrap{
  display: grid;
  grid-template-columns: 1fr 30%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
  .nav__links{
    padding: 2rem;
    text-align: left;
    display: flex;
    align-items: center;
    .menu__title{
      margin-bottom: 10px;
      display: block;
      font-family: $montserrat_black;
      color: $spa2;
      text-transform: uppercase;
    }
    ul{
      padding-left: 0;
      list-style-type: none;
      li{
        a.nav-link{
          display: inline-block;
          padding-top: 6px !important;
          padding-bottom: 6px !important;
          letter-spacing: 0 !important;
          align-items: flex-start !important;
          border-radius: 0 !important;
          line-height: 1.5 !important;
          gap: unset !important;
          font-family: $montserrat_bold;
          transition: all .3s ease;
          .icon{
            display: none !important;
          }
          &:hover{
            color: $spa2 !important;
            transform: translateX(10px);
          }
        }
      }
    }
  }
}
.submenu{
  margin: 0 auto;
  display: grid;
  // grid-template-rows: repeat(3, 5vw);
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  a{
    position: relative;
    display:  block !important;
    white-space: inherit;
    span{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: $montserrat_black;
      color: white;
      background-color: rgba($dark,.2);
      transition: all .3s ease;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all .3s ease;
    }
    &:hover{
      img{
        transform: scale(1.1);
      }
      span{
        background-color: rgba($dark, .6);
      }
    }
  }
  // a:nth-child(1) { grid-area: 1 / 1 / 3 / 2; }
  // a:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
  // a:nth-child(3) { grid-area: 2 / 2 / 3 / 3; }
}
@keyframes fade_move_down_2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

.navbar-nav{
  align-items: center;
  @include media-breakpoint-up(lg) {
    gap: 2.6rem;

  }
  &.last{
    gap: 1rem;
  }
}
.search__btn{
  border-radius: 20px;
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: $primary;
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.10)) drop-shadow(0px 5px 11px rgba(0, 0, 0, 0.10)) drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.09)) drop-shadow(0px 45px 27px rgba(0, 0, 0, 0.05)) drop-shadow(0px 81px 32px rgba(0, 0, 0, 0.01)) drop-shadow(0px 126px 35px rgba(0, 0, 0, 0.00));
}

.flag{
  width: 20px;
  height: 15px;
  display: inline-block;
  background-size: contain;
  margin-right: 5px;
  &.fr{
    background-image: url(../img/icons/fr.svg);
  }
  &.de{
    background-image: url(../img/icons/de.svg);
  }
  &.en{
    background-image: url(../img/icons/gb.svg);
  }
  &.nl{
    background-image: url(../img/icons/nl.svg);
  }
}


.grid{
  display: grid;
  &.simple{
    grid-template-columns: repeat(2, 25vw);
    gap: 2rem;
  }
}


.thermal__submenu{
  padding: 1rem;
  margin: 0;
  border-radius: 8px;
  transition: all .3s linear;
  &.show{

  }
  a{
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    font-family: $montserrat_black;
    color: white;
    text-transform: uppercase;
    img{
      width: 100%;
      height: 100%;
      max-height: 200px;
      object-fit: cover;
      transition: all .3s ease;
    }
    span{
      padding: 2rem;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;bottom: 0;
      background-color: rgba($dark, .2);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  .grid{
    &.simple{
      grid-template-columns: repeat(3, 15vw);
      gap: 1rem;
    }
  }
}

#menuButton{
  background-color: $primary;
  border: 0;
  height: 40px;
  width: 40px;
  // display: inline-flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center
}
.burger-icon {
  @include burger(20px, 2px, 5px, white);
  margin-left: auto;
  margin-right: auto;
}

.is-active .burger-icon {
  @include burger-to-cross;
}


.navbar-collapse{
  @include media-breakpoint-down(sm) {
    background-color: lighten($spa, 6);

  }

}

.navbar{
  .container-fluid{
    @include media-breakpoint-down(sm) {
      padding: 0px;
    }
  }
}
.first__nav{
  @include media-breakpoint-down(sm) {
    margin-bottom: 0 !important;
  }
}

.navbar-nav.last{
  @include media-breakpoint-down(sm) {
    background-color: $white;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
}
