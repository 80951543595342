.btn{
  font-family: $montserrat_bold;;
}
.btn-primary{
  --bs-btn-color: #fff;

  box-shadow: 0 11px 1px rgba(0,0,0,.01), 0 -35px 27px rgba(0,0,0,.05), 0 20px 20px rgba(0,0,0,.09), 0 5px 11px rgba(0,0,0,.1), 0 0 0 rgba(0,0,0,.1);
  // box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 5px 11px 0px rgba(0, 0, 0, 0.10), 0px 20px 20px 0px rgba(0, 0, 0, 0.09), 0px 45px 27px 0px rgba(0, 0, 0, 0.05), 0px 81px 32px 0px rgba(0, 0, 0, 0.01), 0px 126px 35px 0px rgba(0, 0, 0, 0.00);
}
.btn-secondary,
.btn-citron{
  --bs-btn-color: #fff;
}
