

html {
  font-size: 62.5%;
  /* Sets up the Base 10 stuff */
  scroll-behavior: smooth;
  &:not([lang="fr"]){
    .therms{
      display: none;
    }
  }
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  display: flex;
  flex-direction: column;
  color: #2e303d;
  p{
    line-height: 28px;
  }
}
main {
  flex: 1 0 auto;
  >.container:first-child{
    margin-top: 2rem;
  }
}
#footer {
  flex-shrink: 0;
}
a{
  text-decoration: none;
}

h1 {
  font-weight: 900;
  font-size: 44px;
  line-height: 54px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;

}
h2, h3{
  font-weight: 700;
}

h2 {
  font-size: 30px;
  @include media-breakpoint-down(lg) {
    font-size: 24px;
  }
}

h3 {
  font-size: 20px;
}

h4{
  font-size: 18px;
}
h5{
  font-size: 17px;
}
h6{
  font-size: 16px;
}

.position-relative{
  position: relative;
}


.alert{
  p:last-child{
    margin-bottom: 0;
  }
}





.visually-hidden-focusable{
  display: none;
}


.social-share-buttons{
  a{
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    color: white;
    background-color: $dark;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0.4rem;
    &.facebook-share-button{
      background-color: $facebook;
    }
    &.linkedin-share-button{
      background-color: $linkedin;
    }
    &.twitter-share-button{
      background-color: $twitter;
    }
  }
}

.share{
  .dropdown-menu{
    min-width: auto;
  }
}



//tips

// img[alt=""],
// img:not([alt]){
//   border: 4px solid red;
// }


.btn{
  font-weight: 700;
}


.breadcrumb {
  li + li {
    margin-left: 5px;
    font-weight: bold;
    &::before {
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      content: "\f105" !important;
      display: inline-block;
      margin-right: 5px;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.legalnotice-list {
  list-style-type: none;
  padding-left: 0;
  h2 {
    color: $primary;
    font-size: 20px;
  }
  > li {
    margin-bottom: 30px;
  }
}

.col{
  @include media-breakpoint-down(sm) {
    min-width: 100%;
  }
}

#id_error_page {

  height: 100vh;

  main {
    display: flex;
    padding-top: 4rem;
    justify-content: center;
    text-align: center;
    max-height: 100vh;
    // background-color: $lotus;
    h1 {
      text-transform: uppercase;
    }
    h1,
    p {
      max-width: 600px;
      margin: 10px auto;
    }
    .btn {
      background-color: $primary;
      color: white;
      i {
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }
}




.detail__facilities{

  .icn{
    @extend .fa-solid;
    @extend .fa-check;
    &.sprite_shower,
    &.sprite_1-shower-room{
      @extend .fa-shower;
    }
    &.sprite_lift{
      @extend .fa-shower;
    }
    &.sprite_restaurant{
      @extend .fa-utensils;
    }
    &.sprite_bar{
      @extend .fa-martini-glass;
    }
    &.sprite_no-smoking{
      @extend .fa-ban-smoking;
    }
    &.sprite_bed-90-cm,
    &.sprite_bed-160-cm,
    &.sprite_140-cm-bed{
      @extend .fa-bed;
    }
    &.sprite_private-wc{
      @extend .fa-toilet;
    }
    &.sprite_telephone{
      @extend .fa-phone;
    }
    &.sprite_free-private-internet-access,
    &.sprite_wi-fi,
    &.sprite_internet-access-in-rooms{
      @extend .fa-wifi;
    }
    &.sprite_television,
    &.sprite_cablesatellite{
      @extend .fa-tv;
    }
    &.sprite_pets-welcome,
    &.sprite_pets-supplement{
      @extend .fa-dog;
    }
    &.sprite_breakfast{
      @extend .fa-mug-saucer;
    }
    &.sprite_baby-equipment,
    &.sprite_baby-chair,
    &.sprite_changing-table{
      @extend .fa-baby;
    }
    &.sprite_baby-bed{
      @extend .fa-baby-carriage;
    }
    &.sprite_air-conditioned-restaurant{
      @extend .fa-fan;
    }
    &.sprite_car-park,
    &.sprite_private-parking,
    &.sprite_free-car-park{
      @extend .fa-square-parking;
    }
    &.sprite_separate-toilet,
    &.sprite_public-wc{
      @extend .fa-toilet-portable;
    }
    &.sprite_camping-car{
      @extend .fa-trailer;
    }
    &.sprite_park, &.sprite_shady-grounds{
      @extend .fa-tree;
    }
    &.sprite_water-supply{
      @extend .fa-faucet-drip;
    }
    &.sprite_household-waste-depot, &.sprite_emptying-of-grey-water{
      @extend .fa-recycle;
    }
    &.sprite_fenced-grounds{
      @extend .fa-square;
    }
    &.sprite_air-conditioning{
      @extend .fa-temperature-arrow-down;
    }
    &.sprite_room-reserved,
    &.sprite_reception-staff-sensitized-to-the-reception-of-people-with-disabilities,
    &.sprite_site-building-totally-accessible,
    &.sprite_accessible-for-self-propelled-wheelchairs,
    &.sprite_accessible-for-wheelchairs-with-assistance{

      @extend .fa-wheelchair-move;
    }
    &.sprite_hearing-disability{
      @extend .fa-ear-listen;
    }
    &.sprite_board-games{
      @extend .fa-dice;
    }
    &.sprite_meeting-room{
      @extend .fa-person-chalkboard;
    }
    &.sprite_fridge,
    &.sprite_freezer{
      @extend .fa-snowflake;
    }
    &.sprite_bath{
      @extend .fa-bath;
    }
    &.sprite_sofa{
      @extend .fa-couch;
    }
    &.sprite_heating{
      @extend .fa-hot-tub-person;
    }
    &.sprite_dvd-player{
      @extend .fa-compact-disc;
    }
    &.sprite_kitchen{
      @extend .fa-kitchen-set;
    }
    &.sprite_oven{
      @extend .fa-fire-burner;
    }
    &.sprite_garden,
    &.sprite_shared-garden,
    &.sprite_terrace{
      @extend .fa-leaf;
    }
    &.sprite_spa,
    &.sprite_steam-room,
    &.sprite_sauna{
      @extend .fa-spa;
    }
    &.sprite_sports-hall{
      @extend .fa-dumbbell;
    }
    &.sprite_private-garage,
    &.sprite_equipment-store-closed,
    &.sprite_garage{
      @extend .fa-warehouse;
    }
    &.sprite_room-service{
      @extend .fa-broom;
    }
  }
}




blockquote{
  position: relative;
  margin: 1rem 0;
  padding: 3rem 4rem;
  font-family: $dejanire;
  font-style: italic;
  font-size: 2rem;
  letter-spacing: 1px;
  @include media-breakpoint-down(sm) {
    padding-top: 5rem;
    font-size: 1.6rem;
  }
  &::before,
  &::after{
    font-family: $bounce;
    content: '"';
    line-height: .5;
    font-size: 14rem;
    color: $lotus;
    position: absolute;
  }
  &::before {
    display: inline-block;
    transform: rotate(180deg);
    left: -1rem;
    top: -2rem;
    @include media-breakpoint-down(sm) {
      left: 5rem;
      top: -6rem;
    }
  }
  &::after{
    right: -1rem;
    bottom: 0;
    @include media-breakpoint-down(sm) {
      right: 30px;
      bottom: -50px;
    }
  }
}


.modal{
  z-index: 999999;
}


#searchModal{
  .modal-content{
    padding: 3rem;
  }
  form{
    p{
      margin-bottom: 0;
      font-family: $montserrat_boldItalic;
      color: $spa2;
    }
    h3{
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 1px;
    }
    .search__form-inner{
      margin-bottom: 2rem;

      display: flex;
      flex-direction: column;
      width: 100%;
      input{
        padding: 1rem;
        border: 1px solid rgba($primary, .8);
        display: block;
        margin-bottom: 10px;
      }
      .search_btn{
        background-color: $primary;
        border: 0;
        padding: 1rem;
        font-family: $montserrat_bold;
        display: block;
        transition: all .3s ease;
        &:hover{
          background-color: $dark;
          color: white;
        }
      }
    }
  }
  .btn-close{
    position: absolute;
    top: -15px;
    right: -15px;
    opacity: 1;
    background-color: $primary;
    border-radius: 15px;
    padding: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-size: 10px;
  }
}


.frm__search{
  padding: 2rem;
  background-color: rgba($sauge,.1);
  position: sticky;
  top: 200px;
  label{
    font-family: $montserrat_bold;
    display: block;
  }
  input{
    display: block;
    width: 100%;
    &[type="search"]{
      padding: 1rem;
    }
  }
}

.plugin__search{
  .pagenav{
    ul{

      align-items: center;
      margin-top: 2rem;
    }
  }
  .result-list{
    li{
      border: 1px solid #eee;
      &:not(:last-child){
        border-bottom: 0;
      }
      transition: all .3s ease;
      padding: 1rem 2rem;
      .highlighted {
        font-weight: bold;
        display: inline-block;
        background-image: linear-gradient( white 50%, $sauge 50%);
        transition: background .3s ease;
      }
      a{
        transition: color .3s ease;
      }
      &:hover{
        background-color: rgba($sauge,.1);
        .highlighted{
          background-image: linear-gradient( white 20%, $sauge 80%);
        }
        a{
          color: $dark;
        }
      }
    }
  }
  .results-count{
    font-family: $montserrat_bold;
  }
}


.fobi-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  .form-group{
    margin-bottom: 10px;
    &.form-element-wrapper-adresse,
    &.form-element-wrapper-complement_dadresse,
    &.form-element-wrapper-message{
      grid-column: 1/-1;
    }
  }
  input.form-control{
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 0;
  }
  label{
    font-size: 80%;
    font-family: $montserrat_bold;
    display: block;
    margin-bottom: 10px;
  }
}
.form-required-field-message-wrapper{
  background-color: rgba($primary, .3);
  padding: 1rem;
  border: 1px solid $primary;
  margin-bottom: 20px;
  grid-column: 1/-1;
}
.required-field{
  color: $danger;
}

@mixin list-item-style($background-lighten, $border-lighten) {
  background-color: lighten($primary, $background-lighten);
  border-bottom: 1px solid lighten($primary, $border-lighten);
}



#sitemap{
  @include make-container();
  max-width: 1320px;
  >ul{
    >li{
      >a{

        @include list-item-style(20, 2);
      }
      >ul{
        >li{
          >a{
            @include list-item-style(25, 4);
          }
          >ul{
            >li{
              >a{
                @include list-item-style(30, 20);
              }
              >ul{
                >li{
                  >a{
                    @include list-item-style(35, 28);
                  }
                }
              }
            }
          }
        }

      }
    }
  }
  ul{
    list-style: none;

    li{
      border-left: 1px solid $primary;

      a{
        padding: 10px;
        transition: all .3s ease;
        display: block;
        color: inherit;
        &:hover{
          color: $secondary;
          background-color: rgba($secondary, .2);
        }
      }
    }
  }
}


.express__form{
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  label{
    font-weight: 600;
  }
}


.alerte_message{
  .djangocms-popup{
    z-index: 99999;
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

    @include media-breakpoint-up(lg) {
      min-width: 600px;
    }
    .icon{
      font-size: 28px;
    }
    h2{
      font-size: 20px;
    }
    .subject{
      padding: 1rem;
      p:last-child{
        margin-bottom: 0;
      }
    }
    &.info{
      border-left: 5px solid $primary;
      border-radius: 3px;
      .icon{
        color: $primary;
      }
      h2{
        color: $primary;
      }
    }
    &.danger{
      border-left: 5px solid $danger;
      border-radius: 3px;
      .icon{
        color: $danger;
      }
      h2{
        color: $danger;
      }
    }
    &.warning{
      border-left: 5px solid $warning;
      border-radius: 3px;
      .icon{
        color: $warning;;
      }
      h2{
        color: $warning;;
      }
    }
  }
}


.djangocms-popup-reopen{
  width: 50px;
  height: 50px;
  z-index: 1;
  background-color: $primary;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 3s; /* don't forget to set a duration! */
  animation-iteration-count: infinite;
  &::before{
    color: $white;
    background-color: transparent;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f129";
  }
}


#content{
  padding-left: 10px;
  padding-right: 10px;
}
