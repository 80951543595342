.video {
  p{
    color: white;
  }
  position: relative;
  .video-player {
    padding: 1rem;
  }
}

.cookieoptin-notice:not(.cookieoptin-hide) + iframe,
.cookieoptin-notice:not(.cookieoptin-hide) + .fluid-width-video-wrapper {
  display: none;
}

.cookieoptin-hide {
  display: none;
  &.cookieoptin-notice + iframe{
    display: block;
  }
}



$optin_primary:$primary;
$optin_slider: #2980b9;

.cookieoptin-notice{
  padding: 20px;
  background-color: #222;
  border:1px solid #fff;
  strong{
    color: white;
    display: block;
  }
  a{
    color: $optin_primary;
  }
  .btn{
    box-shadow: none;
    border-radius: 0;
    padding: 10px 15px;
    border:0;
    &.btn-success{
      background-color: $optin_primary;
      color: white;
    }
    &.btn-decline{
      border: 1px solid #888;
      color: #444;
      background-color: transparent;
      background-color: $danger;
    }
  }
}

#klaro{
  .cookie-modal{
    z-index: 99999;
  }
  .klaro{
    input:checked + .cm-app-label .slider{
      background-color: $optin_slider;
    }
    p, h1, h2, ul, li, strong{
      color: #333;
    }
    a{
      color:$optin_primary ;
    }
    h1{
      font-size: 24px;
      font-weight: bold;
    }
    /* ---- Btn ----*/
    .cm-btn{
      box-shadow: none;
      border-radius: 0;
      &:nth-child(2){
        background-color: $danger;
      }
      &.cm-btn-success{

        background-color: $optin_primary;
      }
      &.cm-btn-info{
      }
      &.cn-decline{
        border: 1px solid #fff;
        color: #888;
        background-color: transparent;
        &:hover{
          border: 1px solid #ccc;
        }
      }
    }
    .cm-modal{
      background-color: #fff;
      border-radius: 10px;

      h1{
        line-height: 1.5;
      }
      .cm-header, .cm-footer{
        border-color: #eee;

      }
      .cm-toggle-all{
        border-top: 0;
      }
      .cm-body{
        padding: 30px;
      }
    }


    .cookie-notice {
      background-color: #fff;
      box-shadow: none;
      border-radius: 0;
      border: 1px solid #ccc;
      @media (min-width: 990px){
        max-width: 600px;
      }
      p:first-child{
        padding-left: 80px;
      }
      &::before{
        content:"";
        position: absolute;
        top: 20px;
        left: 20px;
        height: 50px;
        width: 50px;
        background-size: cover;
        background-image: url('../img/cookie.png');
      }


      .cn-ok{
        display: flex;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #eee;
        .cm-btn:last-child{
          margin-left: auto;
          margin-right: 0;
        }
        @include media-breakpoint-down(sm) {
          flex-direction: column;
          .cm-btn:not(:nth-child(even)){
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}




.video {
  position: relative;
  .video-player {
    padding: 1rem;
  }
}
.cookieoptin-notice + iframe {
  display: none;
}

.cookieoptin-hide {
  display: none;
  &.cookieoptin-notice + iframe{
    display: block;
  }
}
