.icon{
  display: inline-block;
}
.icon-wishlist{
  background: url(../img/icons/heart.svg);
  width: 26px;
  height: 26px;
  background-size: cover;
}
.icon-search{
  background: url(../img/icons/search.svg);
  width: 18px;
  height: 17px;
}
