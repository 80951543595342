.skiplinks {
  position: absolute;
  transform: translateY(-100%);
  padding: 1rem 0;
  background-color: #eee;
  a {
    color: #000;
  }

  .skiplinks__list {
    list-style-type: none;

    li {
      display: inline-block;
      margin: 0 1rem;
    }
  }

  &:focus-within {
    position: relative;
    transform: translateY(0);
  }
}
