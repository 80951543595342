$fa-font-path: "../fonts/fontawesome/";
$primary: #72C7D2;
$secondary: #D3927E;
$link: #18638d;
$danger: #b94241;
$green: #45883d;
$citron: #45883d;
$sauge: #abd29b;
$niagara: #18638d;
$lotus: #e2c0bf;
$spa:#c7e5e1;
$spa2:#5E938C;
$dark: #000;
$white: #fff;
$yellow: #FFC700;
$btn-border-radius : 0;
// $enable-rounded: false;
$body-color: $dark;
$font-size-base: 1.6rem;
$facebook: #3b5998;
$linkedin : #0376A8;
$twitter: #00aced;
$google: #dd4b39;
$pinterest: #cb2027;
$instagram: #3f729b;
$tripadvisor: #589442;
$flickr: #0063dc;
$youtube: #cd201f;
$instagram: #2f598b;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "dark": $dark,
  "light": $white,
  "sauge": $sauge,
  "lotus": $lotus,
  "niagara": $niagara,
  "info": $spa,
  "success":  $green,
  "warning":  $yellow,
  "citron":  $citron
);
$custom-colors: (
  "spa": #c7e5e1
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

$tooltip-bg:      $secondary;
$tooltip-color:   darken($secondary, 40);
$btn-padding-y-lg:            10px;
$btn-padding-x-lg:            30px;
$btn-color : #fff !important;
$btn-padding-y: 10px;
$btn-padding-x: 30px;

$btn-border-radius: 30px;
@font-face {
  font-family:"Montserrat Medium";
  src:url("../fonts/Montserrat/Montserrat-Medium.woff2") format("woff2"),
  url("../fonts/Montserrat/Montserrat-Medium.woff") format("woff");
  font-style:normal;
  font-weight: 500;
}
@font-face {
  font-family:"Montserrat Light";
  src:url("../fonts/Montserrat/Montserrat-Light.woff2") format("woff2"),
  url("../fonts/Montserrat/Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family:"Montserrat Thin";
  src:url("../fonts/Montserrat/Montserrat-Thin.woff2") format("woff2"),
  url("../fonts/Montserrat/Montserrat-Thin.woff") format("woff");
  font-style:normal;
  font-weight:100;
}

@font-face {
  font-family:"Montserrat Bold";
  src:url("../fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
  url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff");
  font-style:normal;
  font-weight: 700;
}
@font-face {
  font-family:"Montserrat Bold italic";
  src:url("../fonts/Montserrat/Montserrat-BoldItalic.woff2") format("woff2"),
  url("../fonts/Montserrat/Montserrat-BoldItalic.woff") format("woff");
  font-style:normal;
  font-weight: 700;
}
@font-face {
  font-family:"Montserrat Black";
  src:url("../fonts/Montserrat/Montserrat-Black.woff2") format("woff2"),
  url("../fonts/Montserrat/Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family:"Bounce Script W00 Regular";
  src:url("../fonts/bounce/Bounce Script W00 Regular.woff2") format("woff2"),
  url("../fonts/bounce/Bounce Script W00 Regular.woff") format("woff");
  font-style:normal;
  font-weight:400;
}
@font-face {
  font-family:"Dejanire Headline";
  src:url("../fonts/DejanireText/DejanireText-Black.woff2") format("woff2"),
  url("../fonts/DejanireText/DejanireText-Black.woff") format("woff");
  font-style:normal;
  font-weight:400;
}



$montserrat_regular: "Montserrat Medium";
$montserrat_light: "Montserrat Light";
$montserrat_thin: "Montserrat Thin";
$montserrat_bold: "Montserrat Bold";
$montserrat_boldItalic: "Montserrat Bold Italic";
$montserrat_black: "Montserrat Black";
$bounce: "Bounce Script W00 Regular";
$dejanire: "Dejanire Headline";
$font-family-base: $montserrat_regular;
$headings-font-family: $montserrat_black !default;


$modal-fade-transform: scale(.3);
