a{
  &:not([class]){
    color: $primary;
  }
}
a:link{
  text-decoration-skip: ink;
}
a[href^="http"]:empty::before{
  content: attr(href);
}



// Setup the function in your functions or helpers files, wherever you keep these bits.
@function headings($from:1, $to:6) {
  @if $from == $to {
    @return 'h#{$from}';
  } @else {
    @return 'h#{$from},' + headings($from+1, $to);
  }
}

// Then call it with the rules/styles you want wherever you want (As long as the file/include has access to the above function of course).
#{headings(1,6)} {
  line-height: 1.5;
}

.bounce{
  font-family: $bounce;
  line-height: 1.5;
  font-weight: normal;
}
.dejanire{
  font-family: $dejanire;
  line-height: 1.5;
  font-weight: normal;
}

h1{
  @include media-breakpoint-down(sm) {
    font-size: 3rem;
    line-height: 1.5;
  }
}

h2.bounce{
  font-size: 50px;
}
h3.bounce{
  font-size: 40px;
}
h4.bounce{
  font-size: 30px;
}
