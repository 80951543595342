.brochure-item{
  position: relative;
  .brochure-cover{
    background-color: $dark;
    display: block;
    margin-bottom: 10px;
    border-radius: 6px;
    img{
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
      box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
      box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
      box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
      max-width: 100%;
      height: auto;
      transition: all .3s ease;
    }
  }
  .brochure-informations{
    padding: 10px;
  }
  .brochure-description{
    position: absolute;
    padding: 10px;
    top: 20px;
    left: 20px;
    max-width: 80%;
    font-family: $montserrat_bold;
    color: white;
    font-size: 80%;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.8;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    transform: translate(-50%, -50%);
  }
  .brochure-title{
    font-family: $dejanire;
    font-size: 24px;
    margin-bottom: 10px;
  }
  .brochure-links{
    a{
      display: flex;
      position: relative;
      gap: 1rem;
      align-items: center;
      font-family: $montserrat_bold;
      &::before{
        width: 20px;
        height: 26px;
        display: inline-block;
        content: "";
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        content: "\f019";
      }
      &.brochure-link-view{
        &::before{
          content: "\f06e";
        }
      }
    }
  }
  &:hover{
    img{
      opacity: .3;
    }
    .brochure-description{
      opacity: 1;
      visibility: visible;
      transform: translate(0);
    }
  }
}


.brochure-row{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }

}
