.svg-wrapper{
  position: relative;
  margin-top: -80px;


  svg{
    width: 100%;
    height: auto;
  }
}
.page_header{
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  margin-bottom: 4rem;
  h1{
    padding-bottom: 0;
    &::after{
      display: none;
    }
  }
}

.slider_home{
  position: relative;
  .slide_bottom{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background-color: white;
    position: absolute;
    bottom: 60px;
    left: 50%;
    margin-left: -25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transform: translate(-50%, 0%) ;
    animation: fade_move_down 2s ease-in-out infinite;
    cursor: pointer;
    @include media-breakpoint-down(lg) {
      display: none;
    }
    .icon_scroll{
      background-image: url(../img/scroll.svg);
      display: block;
      width: 10px;
      height: 15px;

      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}


@keyframes fade_move_down {
  0% {
    transform: translate(0, -10px);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate(0, 10px);
    opacity: 0;
  }
}

#id_index{
  main{
    section:not(.slider_home){
      h2{
        text-transform: uppercase;
        line-height: 1.2;
        @include media-breakpoint-up(lg) {
          font-size: 4rem;
        }
      }
    }

  }
  @include media-breakpoint-down(sm) {
    section{
      text-align: center;
    }
  }
}
.modals{
  @include media-breakpoint-down(sm) {
    display: flex;
    max-width: 100%;
    overflow: scroll;
    .modal{
      all: unset;
      min-width: 90%;
      .modal-dialog{
        border: 1px solid #eee;
        transform : unset;
        min-height: 100%;
        display: block;
        border-radius: 10px;
      }
      .card{
        border: 0;
        h3{
          color: $primary;
        }
      }
    }
  }
}
.overlay{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.5;
  }
}

.menu_wrapper{
  display: grid;
  gap: 2rem;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .card__item{
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 8px;
    @include media-breakpoint-down(lg) {
      border: 1px solid rgba($sauge, .3);
      padding: .5rem;
      color: $dark;
    }
    .card__img{
      width: 100%;
      border-radius: 8px;
      @include media-breakpoint-up(lg) {
        height: 100%;
        object-fit: cover;
      }
    }
    .card__body{
      text-align: center;
      @include media-breakpoint-up(lg) {
        color: white;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba($dark, .2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all .3s ease;
        padding: 2rem;
      }
      @include media-breakpoint-down(lg) {
        margin-top: 10px;
        color: inherit;
      }
      h2{
        font-size: 20px;
        text-transform: inherit;
        line-height: 1.2;
        transition: all .3s ease;
        transform: scale(1);
        @include media-breakpoint-down(sm) {
          font-size: 16px;
          font-family: $montserrat_bold;

        }
      }
      .description{
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
        position: absolute;
        top: 10px;
        right: 10px;
        left: 10px;
        bottom: 10px;
        padding: 20px;
        line-height: 1.5;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        font-family: $montserrat_bold;
        font-size: 12px;
        letter-spacing: 1px;
        transform: scale(0);
        background-color: transparent;
        border-radius: 10px;
      }
    }

    &:hover{
      .card__body{
        background-color: rgba($dark, .4);
        .description{
          visibility: visible;
          opacity: 1;
          transform: scale(1);
          background-color: $primary;
          color: rgba($dark, .5);
        }

      }
    }

  }
  // .card__item:nth-child(1) { grid-area: ar1; }
  // .card__item:nth-child(2) { grid-area: ar2; }
  // .card__item:nth-child(3) { grid-area: ar3; }
  // .card__item:nth-child(4) { grid-area: ar4; }
  // .card__item:nth-child(5) { grid-area: ar5; }
  // .card__item:nth-child(6) { grid-area: ar6; }
  // .card__item:nth-child(7) { grid-area: ar7; }
}



.activity__list{
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );
  @include media-breakpoint-up(lg) {
    gap: 3rem;
    margin-bottom: 3rem;
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 1rem;
    gap: 2rem;
  }
  .activity__list--item{
    margin-bottom: 0;
    height: 100%;
    position: relative;
    background-color: white;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 20px;
    overflow: hidden;
    img{
      min-width: 100%;
      height:auto;
      transition: all .3s ease;
    }
    figcaption {
      padding: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      .fig__title {
        font-family: $montserrat_bold;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1.2;
        transition: all .3s ease;
        margin-bottom: 10px;
      }
      .date{
        font-family: $montserrat_black;
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
        .date-range-day{
          text-transform: capitalize;
        }
        .date-range-from, .date-range-to{
          display: block;
          font-size: 14px;
          font-family: $montserrat_light;
        }
      }
      .city{
        font-family: $montserrat_bold;
        font-size: 12px;
      }
    }
    &:hover{
      .fig__title{
        color: $primary;
      }
    }
  }
}


.gallery {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 2rem;

}

.gallery__img {
  width: 100%;
  height: auto;
  display: block;
}


//activity-detail

.detail__header{
  padding: 4rem 0;
  background-color: rgba($spa, .4);
  margin-bottom: 2rem;
}
.content__detail{
  padding-bottom: 3rem;
  h2{
    text-transform: uppercase;
  }
  hr{
    margin-top: 3rem;
    margin-bottom: 3rem;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  }
}

.location-map-wrapper{
  position: relative;
}
// .address-map {
//   height: 432px;
//   position: relative;
//   z-index: 1;
// }

.map__widget{
  padding: 20px;
  text-align: center;
}
.detail__facilities{
  list-style-type: none;
  padding-left: 0;
  display: grid;
  margin-bottom: 2rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
    li{
      padding: 10px 5px;
      span{
        display: inline-block;
        &:first-child{
          margin-right: 10px;
        }
      }
      i{
        font-size: 22px;
        color: $primary;
      }
    }
  }
}


.activity-address{
  p{
    margin-bottom: 0;
  }
}


.must__see{
  position: relative;
  overflow: hidden;
  .container{
    position: relative;
    z-index: 1;
  }
  @include media-breakpoint-up(lg) {

    padding-top: 8rem;
    padding-bottom: 8rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  @include media-breakpoint-down(sm) {
    background-color: rgba($primary, .43);
    padding-top: 3rem;
    padding-bottom: 0rem;
  }
  svg{
    position: absolute;
    left: 0;
    right: 0;
    top: -14rem;
    opacity: .2;
    width: 100%;
    pointer-events: none;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .slick-slide{
    padding: 1.5rem;
  }
  &_slider{
    @include media-breakpoint-up(lg) {
      margin-top: 4rem;

    }
    .slide{
      position: relative;
      border-radius: 20px;
      overflow: hidden;
      img{
        border-radius: 20px;
        max-width: 100%;
        height: auto;
      }
      &__content{
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(18, 18, 18, 0.75) 48.67%);
        padding: 40px 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0px 0px 20px 20px;
        color: white;
        text-align: center;
        .description{
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 0;
          transition: all .3s ease;
        }
      }
      &:hover{
        .slide__content{
          .description{
            height: 100px;
          }
        }
      }
    }
    .arrow{
      position: absolute;
      top: 50%;
      z-index: 1;
      &.prev{
        left: -20px;
      }
      &.next{
        right: -20px;
        transform: scale(-1);
      }
    }
  }
}
.situation{
  @include media-breakpoint-down(sm) {
    padding-bottom: 3rem;
    .container{
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .card {
    img{
      max-height: 200px;
      object-fit: cover;
    }
    p{
      line-height: 1.4;
    }
  }
}

.bg_waves{
  background-image: url(../img/bg_waves.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.hiking{
  @include media-breakpoint-up(lg) {
    margin-top: 8rem;

    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  background-image: url(../img/bg_waves.svg);
  background-size: cover;
  @include media-breakpoint-down(sm) {
    background-color: white;
    background-size: 90vh;
    padding-top: 3rem;
    margin-top: 2rem;
    .container{
      padding-left: 30px;
      padding-right: 30px;
    }
    .col{
      margin-bottom: 3rem;
    }
  }
}
.agenda, .bg_radius{
  position: relative;
  @include media-breakpoint-up(lg) {

    padding-top: 8rem;

  }
  &::before{
    content: "";
    display: block;
    width: 40%;
    height: 100%;
    border-radius: 0px 450px 450px 0;
    background-color: $spa;
    opacity: .5;
    position: absolute;
    left: 0vw;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
}

.agenda{
  padding-bottom: 8rem;
  position: relative;
  min-height: 600px;
  @include media-breakpoint-up(lg) {
    margin-top: 4rem;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 3rem;
  }

  .events{
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

    }
    gap: 30px;
    margin-top: 20px;
    @include media-breakpoint-down(sm) {
      display: flex;
      padding: 2rem;
      overflow-x: scroll;
      .event{
        min-width: 90%;
      }
    }
  }
  .event{
    position: relative;
    overflow: hidden;
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.20);
    a{
      text-indent: -9999999px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    &, img{
      border-radius: 20px;
      transition: all .3s ease;
    }
    .date{
      font-family: $montserrat_black;
    }
    &__content{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      bottom: 0;
      left: 0;
      top: 0;
      right: 0;
      text-align: center;
      padding: 40px 30px;
      color: white;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 19.57%, #000 68.02%);
      transition: all .3s ease;
      background-color: rgba($primary, 0);

      h3{
        color: $secondary;
        line-height: 1.4;
        margin-bottom: 10px;
      }
      .date{
        font-size: 18px;
        margin-bottom: 10px;
      }
      .description{
        text-transform: none;
        opacity: 0;
        transform: translate3d(0,-10px,0);
        transition: height 0.35s, opacity 0.35s, transform 0.35s;
        height: 0;
      }
    }
    &:hover{
      img{
        transform: scale(1.8);
      }
      .event__content{
        background-color: rgba($primary, .6);
        top: 0;
        .description{
          transform: translate3d(0,0,0);
          opacity: 1;
          height: 60px;
        }
      }
    }
  }
}
.agenda_link{
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
}

.hike__list {
  display: grid;
  gap: 30px 30px;
  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 30px 1fr 1fr 20px;
    height: 600px;
    grid-template-areas:
    ". item2"
    "item1 item2"
    "item1 item3"
    ". item3";

  }
  .item{
    background-size: cover;
    box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    figure{
      border-radius: 20px;
      border-radius: 20px;
      margin-bottom: 0;
      min-height: 100%;
      display: block;
      background-size: cover;
      background-position: bottom;
    }
    figcaption{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      border-radius: 20px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
      text-align: center;
      color: white;
      padding: 40px;
    }
  }

  .item:nth-child(1){
    @include media-breakpoint-up(lg) {

      grid-area: item1;
    }
  }
  .item:nth-child(2){
    @include media-breakpoint-up(lg) {
      grid-area: item2;
    }
  }
  .item:nth-child(3){
    @include media-breakpoint-up(lg) {
      grid-area: item3;
    }
  }
  figure{
    margin-bottom: 0;
    @include media-breakpoint-down(lg) {
      height: 300px;
    }
  }
}

.title{
  text-transform: uppercase;
  color: $secondary;
  letter-spacing: 2.4px;
  font-weight: 500;
}


.blog__latest,
.wave,
.wave2{
  position: relative;
  @include media-breakpoint-up(lg) {

    margin-top: 8rem;
    padding-top: 10rem;
    padding-bottom: 8rem;
  }
  >.container{
    position: relative;
    &::after, &::before{
      content: "";
      border-radius: 90px 0px 0px 0px;

      display: block;
      position: absolute;
      top: -8rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    &::after{
      background-color: $sauge;
      width: 120%;
      height: 300px;
    }
    &::before{
      background-color: rgba($primary, .43);
      transform: translateX(-50%) rotate(4deg);
      width: 100%;
      height: 200px;
    }
    >div{
      position: relative;
      z-index: 1;
    }

  }
}
.blog__latest{
  @include media-breakpoint-down(sm) {
    padding: 4rem 2rem;
  }
  >.container{
    >div{
      h2{
        @include media-breakpoint-up(lg) {
          color: white;
        }
      }
    }
  }
}

.wave, .wave2{
  >.container{
    >div{
      border-top-left-radius: 4rem;
    }
  }
}

.wave2{
  >.container{
    &::after{
      background-color: $spa;
    }
    &::before{
      background-color: rgba($secondary, .43);
    }
  }
}

.blog__latest .blog-latest-entries,
.blog-list{
  display: grid;
  gap: 30px;
  margin-top: 2rem;
  @include media-breakpoint-up(lg) {
    margin-top: 5rem;
    grid-template-columns: repeat(3, 1fr);
  }

}

article.post-item{
  display: flex;
  flex-direction: column;
  .blog-visual{
    order: 1;
    margin-bottom: 2rem;
    img{
      border-radius: 20px;
      box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.20);
      max-width: 100%;
      height: auto;
    }
  }
  header{
    order: 2;
    h3{
      font-family: $montserrat_bold;
      text-transform: inherit;
      a{
        color: inherit;
      }
    }
    .post-detail{
      display: none;
    }
  }
  .blog-lead{
    order: 3;
  }
  .read-more{
    order: 4;
    a{
      color: $secondary;
    }
  }
}

.date{
  span{
    display: inline-block;
    &:not(:first-child){
      margin-left: 5px;
    }
  }
  .date-range-on{
    margin-right: 5px;
  }
}



.header__page{
  background-color: #616161;
  background-size: cover;
  background-blend-mode: overlay;
  background-position: center bottom;
  text-align: center;
  position: relative;
  color: white;
  margin-bottom: 30px;
  overflow: hidden;
  a{
    color: white;
  }
  h1{
    text-shadow: 2px 5px 8px rgba(0,0,0,0.17);
    @include media-breakpoint-down(sm) {
      font-size: 3rem;
      line-height: 1.5;
    }
  }
  .wrap{
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .breadcrumb{
    justify-content: center;
  }
  .svg-wrapper{
    margin-top: 50px;
    height: 100px;
    @include media-breakpoint-down(sm) {
      height: 50px;
      margin-top: 10px;
    }
    svg{
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
    }
  }
  .breadcrumb-item.active{
    color: white;
  }
  .breadcrumb-item {
    &::before{
      color: white;
    }
  }
}


#id_activity_detail{
  .sticky_block{

    position: sticky;
    top: 200px;
    z-index: 1;
  }
  .card__address{
    @include media-breakpoint-up(lg) {
      margin-top: -140px;
    }
    background-color: lighten($spa, 10);
    position: relative;
    margin-bottom: 2rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    h2{
      font-size: 20px;
      margin-top: 1rem;
      color: $dark;
    }
    .rating{
      color: $yellow;
    }
    .card__address--top{
      padding: 3rem;
    }
    a{
      color: inherit;
      display: block;
      margin-bottom: 0.8rem;
      &:hover{
        color: darken($primary, 25);
      }
    }
    .wishlist-controls{
      border-top: 20px solid #fff;
      a{
        display: block;
        padding: 1rem;
        text-align: center;
        background-color: darken($primary, 25);
        color: white;
        &:hover{
          background-color: darken($primary, 35);
        }
        &.remove-from-travel-notebook{
          background-color: $danger;
          &:hover{
            background-color: lighten($danger, 5); ;
          }
        }
      }
    }
    .min__price{
      span{
        display: block;
        &.price{
          font-family: $montserrat_black;
          font-size: 3rem;
          color: $niagara;
        }
      }
    }
  }
  #id_address_map{
    height: 400px;
  }
  section{
    h2{

      font-family: $montserrat_bold;
      border-bottom: 1px solid rgba($primary, .3);
      margin-bottom: 2rem;
      padding-bottom: 3rem;
      font-size: 34px;
      color: $primary;
    }
  }
  h2{
    .char1, .char2, .char3, .char4{
      font-family: $montserrat_thin;
    }
  }
  h3{
    font-size: 18px;
    font-family: $montserrat_bold;
  }
  section + section{
    margin-top: 4rem;
  }
}

.social-share-buttons{
  display: flex;
  flex-wrap: wrap;
}


.city{
  path{
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
      fill: $primary;
    }
  }
}
.city__modal{
  .modal-content{
    border-radius: 10px;
    overflow: hidden;
    border:0;
  }
  .img-thumbnail-wrapper{
    margin-bottom: 2rem;
  }
  .modal-dialog{
    @include media-breakpoint-up(sm) {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      .card{
        overflow: hidden;
        border-radius: 10px;
        text-align: center;
        border:0;
      }
      .card-body{
        all: unset;
        padding: 10px;
        h3{
          color: $primary;
          position: relative;
          &::after{
            content: "";
            width: 35px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
            height: 8px;
            background-color: $sauge;
            border-radius: 4px;
          }
        }
      }

    }
  }
}
.app__blog{
  position: relative;
  padding-top: 50px;
  h1{
    text-transform: none;
    color: $dark;

    font-size: 36px;

    line-height: 1.2;
    font-weight: 800;
  }
  .blog-visual{
    margin-bottom: 3rem;
    margin-top: 3rem;
    border-radius: 10px;
    img{
      box-shadow: 0px 8px 16px -8px rgba(0,0,0,0.30),0px 13px 27px -5px rgba(50,50,93,0.25);;
      border-radius: 10px;
    }
  }
  aside{
    border: 1px solid #eee;
    border-radius: 3rem;
    padding: 3rem;
    .plugin{
      .blog-latest-entries{
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr;
        margin-top: 0;
        .blog-visual{
          margin-top: 0;
        }
      }
      ul{
        list-style-type: none;
        padding-left: 0;
        li{
          a{
            display: block;
            color: inherit;
            border-bottom: 1px solid #eee;
            padding: 1rem 0;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.post-detail.tags{
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  grid-area: 1rem;
  margin-top: 1rem;
  li {
    a{
      background-color: $secondary;
      padding: 0.5rem 1.5rem;
      color: white;
      display: block;
      border-radius: 10px ;
    }
  }
}

.waves {
  position:relative;
  width: 100%;
  height:35vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:450px;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
}

.wavees{
  background: linear-gradient(60deg, $sauge 0%, $spa 100%);
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 00px;
  z-index: -1;
}


.plans-gpx{
  li{
    margin-bottom: 1rem;
  }
}
