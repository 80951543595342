.slider_home{
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    padding-bottom: 60px;
  }
  .slide{
    position: relative;
    .slide-overlay{
      position: absolute;
      @include media-breakpoint-up(sm) {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        &.slide-bottom-right{
          top: unset;
          left: unset;
          bottom: 100px;
          right: 100px;
          transform: unset;
        }
        &.slide-bottom-left{
          top: unset;
          left: unset;
          bottom: 100px;
          left: 100px;
          transform: unset;
        }
        &.slide-top-right{
          top: 100px;
          left: unset;
          right: 100px;
          transform: unset;
        }
        &.slide-top-left{
          top: 100px;
          left: 100px;
          transform: unset;
        }
        &.slide-top-center{
          top: 100px;
          transform: translateY(0) translateX(-50%);
        }
        &.slide-bottom-center{
          bottom: 100px;
          top: unset;
          transform: translateY(0) translateX(-50%);
        }
        &.slide-center-right{
          right: 100px;
          left: unset;
          transform: translateY(-50%) translateX(0);
        }
        &.slide-center-left{
          left: 100px;
          transform: translateY(-50%) translateX(0);
        }
      }
      z-index: 1;
      color: white;
      @include media-breakpoint-down(sm) {
        left: 10px;
        right: 10px;
        bottom: 40px;

      }
      h1{
        font-family: $montserrat_black;
        text-transform: none;
        text-shadow: 1px 1px 12px #090909;
        @include media-breakpoint-down(sm) {
          font-size: 20px;
          line-height: 1.3;
        }
      }
      h2{
        font-size: 110px;
        line-height: 1.2;
        font-family:$montserrat_boldItalic;
        text-transform: lowercase;
        font-style: normal;
        margin-bottom: 0;
        @include media-breakpoint-down(lg) {
          font-size: 28px;
        }
        .char1, .char2{
          font-family: $montserrat_thin;
          font-weight: normal;
        }
        .char3, .char4, .char5, .char6{
          font-family: $montserrat_regular;
          font-weight: normal;
        }
      }
      h3{
        font-family: $montserrat_regular;
        font-size: 40px;
        @include media-breakpoint-down(lg) {
          font-size: 20px;
        }
      }
      p{
        font-size: 25px;
        @include media-breakpoint-down(lg) {
          font-size: 18px;
        }
      }

    }
  }
  .slick-arrow, .dots{
    z-index: 1;
    position: absolute;
  }
  .dots{
    list-style-type: none;
    display: flex;
    padding-left: 0;
    gap: .5rem;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
    @include media-breakpoint-down(lg) {
      display: none;

    }
    button{
      width: 10px;
      height: 10px;
      background-color: white;
      border-radius: 5px;
      border: 0;
      text-indent: -99999px;
      transition: all .3s ease;
    }
    .slick-active{
      button{
        width: 26px;
      }
    }
  }
  .arrow{
    bottom: 60px;


    &.next{
      right: 20px;
      span{
        transform: scale(-1);
      }
    }
    &.prev{
      right: 90px;
      @include media-breakpoint-down(lg) {
        left: 20px;
        right: unset;
      }
    }
    @include media-breakpoint-down(sm) {
      bottom: 50%;
    }
  }
  .item-bg{
    height: 80vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    @include media-breakpoint-down(lg) {
      height: 50vh;
    }
  }
  .svg-wrapper{
    @include media-breakpoint-down(sm) {
      margin-top: -20px;
    }
  }
}

.slick-dots{
  list-style-type: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  li{
    button{
      border: 1px solid $secondary;
      text-indent: -99999999px;
      width: 15px;
      height: 15px;
      border-radius: 8px;
      background-color: transparent;
    }
    &.slick-active{
      button{
        background-color: $secondary;
      }
    }
  }
}
.slick-slider {
  .arrow{

    background-color: white;
    border: 1px solid darken($white,5);
    height: 58px;
    width: 58px;
    display: inline-flex;
    border-radius: 29px;
    align-items: center;
    justify-content: center;
    transition: all .3s linear;
    span{
      display: inline-flex;
      width: 12px;
      height: 9px;
      background-image: url(../img/arrow.svg);
      background-size: contain;
      transition: all .3s ease;
    }

    &:hover{
      background-color: $secondary;
      border-color: $secondary;
      span{
        filter: invert(1);
      }
    }

  }

}


